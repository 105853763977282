import clsx from "clsx";
import { useRef, useEffect } from "react";
import * as echarts from "echarts";

import type { FC, HTMLAttributes } from "react";

type ChartProps = HTMLAttributes<HTMLDivElement> & {
  chartOptions?: echarts.EChartsOption;
  initOptions?: echarts.EChartsInitOpts;
};
const Chart: FC<ChartProps> = (props) => {
  const { className, style, chartOptions = {}, initOptions = {}, ...restProps } = props;
  const echartRef = useRef<echarts.ECharts | null>(null);
  const chartElement = useRef<HTMLDivElement>(null);

  const initChart = () => {
    if (!chartElement.current) return;

    if (echartRef.current && !echartRef.current.isDisposed()) {
      return echartRef.current.setOption(chartOptions);
    }

    echartRef.current = echarts.init(chartElement.current, null, {
      renderer: "canvas",
      width: chartElement.current.clientWidth,
      ...initOptions,
    });
    echartRef.current.setOption(chartOptions);

    window.addEventListener("resize", () => {
      echartRef.current?.resize();
    });
  };
  useEffect(() => {
    if (chartElement.current) {
      initChart();
    }
    return () => {
      if (echartRef.current) {
        window.removeEventListener("resize", () => {
          echartRef.current?.resize();
        });
        // echartRef.current.dispose();
      }
    };
  }, [chartOptions, initOptions]);

  return (
    <div
      ref={chartElement}
      style={style}
      className={clsx("w-full", className)}
      {...restProps}
    ></div>
  );
};
export { echarts };
export default Chart;
