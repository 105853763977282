import { SWRConfig } from "swr";
import { useEffect } from "react";
import { App as F7APP, View, f7ready } from "framework7-react";

import { Capacitor } from "@capacitor/core";
import { VoiceRecorder } from "capacitor-voice-recorder";
import { SplashScreen } from "@capacitor/splash-screen";

import routes from "./router";

import type { Framework7Parameters } from "framework7/types";

const isNative = Capacitor.isNativePlatform();

const App = () => {
  const params: Framework7Parameters = {
    name: "accounting",
    theme: "ios",
    routes,
    iosTranslucentBars: false,
    iosTranslucentModals: false,
    colors: {
      primary: "#6467F0",
    },
    touch: {
      iosTouchRipple: true,
    },
    dialog: {
      title: "提示",
      buttonOk: "确定",
      buttonCancel: "取消",
    },
  };

  useEffect(() => {
    f7ready(() => {
      VoiceRecorder.hasAudioRecordingPermission().then((res) => {
        if (!res.value) {
          return VoiceRecorder.requestAudioRecordingPermission();
        }
      });

      if (isNative) {
        SplashScreen.hide().then();
      }
    });
  }, []);

  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
      }}
    >
      <F7APP {...params}>
        <View
          url="/"
          main={true}
          className="safe-areas"
        />
      </F7APP>
    </SWRConfig>
  );
};

export default App;
