import useSwr from "swr";
import dayjs from "dayjs";
import clsx from "clsx";
import { useRef, useState } from "react";
import { useSetState } from "ahooks";
import {
  Page,
  Navbar,
  Button,
  List,
  ListItem,
  ListInput,
  Actions,
  Segmented,
  ActionsGroup,
  ActionsLabel,
  ActionsButton,
} from "framework7-react";
import Chart, { echarts } from "components/Chart";

import { getSalesReport, getDayReport, getAssetLiability } from "./service";

import type { FC } from "react";

import styles from "./index.module.scss";

const ReportPage: FC = () => {
  const [date, setDate] = useSetState({
    startDate: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });

  const [actionsOpened, setActionsOpened] = useState(false);
  const [actionType, setActionType] = useState("sales");

  const { data, mutate: mutate1 } = useSwr(date, getSalesReport, {});
  const salesData = data?.data;
  const graphList = salesData?.graphList ?? [];
  const userList = salesData?.tableList ?? [];

  const { data: dailyAssets, mutate: mutate2 } = useSwr(date.endDate, getDayReport, {});
  const dailyAssetsData = dailyAssets?.data;

  const { data: assetLiability, mutate: mutate3 } = useSwr(
    {
      ...date,
      s: "",
    },
    getAssetLiability,
    {},
  );
  const assetLiabilityData = assetLiability?.data;
  const rateList = assetLiabilityData?.details ?? [];

  function formatNumber(num: number): string {
    if (num >= 100000000) {
      return (num / 100000000).toFixed(2) + " 亿";
    } else if (num >= 10000) {
      return (num / 10000).toFixed(2) + " 万";
    } else {
      return num.toString();
    }
  }

  const actionTypeRef = useRef("sales");

  return (
    <>
      <Page
        ptr
        onPtrRefresh={(done) => {
          Promise.all([mutate1(), mutate2(), mutate3()]).then(() => {
            done();
          });
        }}
      >
        <Navbar title="报表统计" />
        <div className="flex flex-col gap-[4px] pt-[15px] px-[20px] my-[10px] bg-white">
          <div className="flex-y-center gap-[10px] flex-1">
            <Segmented
              strong
              tag="p"
              className="w-full"
            >
              <Button
                active={actionType === "sales"}
                onClick={() => {
                  setActionsOpened(false);
                  setActionType("sales");
                  actionTypeRef.current = "sales";
                  setDate({
                    startDate: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
                    endDate: dayjs().format("YYYY-MM-DD"),
                  });
                }}
              >
                销售收入分析
              </Button>
              <Button
                active={actionType === "dailyAssets"}
                onClick={() => {
                  setActionsOpened(false);
                  setActionType("dailyAssets");
                  actionTypeRef.current = "dailyAssets";
                  setDate({
                    startDate: dayjs().format("YYYY-MM-DD"),
                    endDate: dayjs().format("YYYY-MM-DD"),
                  });
                }}
              >
                每日资产情况
              </Button>
              <Button
                active={actionType === "assetLiability"}
                onClick={() => {
                  setActionsOpened(false);
                  setActionType("assetLiability");
                  actionTypeRef.current = "assetLiability";
                  setDate({
                    startDate: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
                    endDate: dayjs().format("YYYY-MM-DD"),
                  });
                }}
              >
                资产负债率
              </Button>
            </Segmented>
            {/*<Button*/}
            {/*  tonal*/}
            {/*  type="button"*/}
            {/*  className="w-fit"*/}
            {/*  onClick={() => setActionsOpened(true)}*/}
            {/*>*/}
            {/*  {reportTypeMap[actionType]} <Icon material="keyboard_arrow_down" />*/}
            {/*</Button>*/}
          </div>
          <div className="flex-y-center">
            <span className="text-[13px]">查询日期：</span>
            <List className="m-0 p-0 flex-1">
              <ListInput
                readonly
                clearButton
                type="datepicker"
                className="p-0"
                placeholder="请选择时间"
                value={
                  actionType !== "dailyAssets" ? [date.startDate, date.endDate] : [date.endDate]
                }
                calendarParams={{
                  backdrop: true,
                  rangePicker: actionType !== "dailyAssets",
                  maxDate: new Date(),
                  rangePickerMaxDays: 31,
                  on: {
                    change: (calendar, values: any) => {
                      if (values?.length === 2 && actionTypeRef.current !== "dailyAssets") {
                        calendar.close();
                        return setDate({
                          startDate: dayjs(values[0]).format("YYYY-MM-DD"),
                          endDate: dayjs(values[1]).format("YYYY-MM-DD"),
                        });
                      }
                      if (values?.length === 1 && actionTypeRef.current === "dailyAssets") {
                        calendar.close();
                        setDate({
                          startDate: dayjs(values[0]).format("YYYY-MM-DD"),
                          endDate: dayjs(values[0]).format("YYYY-MM-DD"),
                        });
                      }
                    },
                  },
                }}
              ></ListInput>
            </List>
          </div>
        </div>
        <div className="bg-white">
          {actionType === "sales" && (
            <Chart
              initOptions={{
                height: 260,
              }}
              chartOptions={{
                title: {
                  text: "单位：万元",
                  textStyle: {
                    fontSize: 12,
                    color: "#aaa",
                  },
                  padding: [15, 0, 0, 20],
                },
                tooltip: {
                  trigger: "axis",
                },
                grid: {
                  show: false,
                },
                xAxis: {
                  type: "category",
                  data: graphList?.map((item) => dayjs(item.date).format("MM/DD")) ?? [],
                },
                yAxis: {
                  type: "value",
                  show: false,
                },
                series: [
                  {
                    data: graphList?.map((item) => item.amount / 10000) ?? [],
                    type: "line",
                    smooth: true,
                    areaStyle: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: "rgba(100, 103, 240,0.5)",
                        },
                        {
                          offset: 1,
                          color: "rgba(100, 103, 240,0.1)",
                        },
                      ]),
                    },
                  },
                ],
              }}
            />
          )}

          {actionType === "dailyAssets" && (
            <Chart
              initOptions={{
                height: 260,
              }}
              chartOptions={{
                tooltip: {
                  trigger: "axis",
                  valueFormatter(value: any) {
                    return formatNumber(value);
                  },
                },
                grid: {
                  left: "20%",
                },
                xAxis: {
                  type: "category",
                  data: ["货币资金", "应收账款", "应付账款", "长期借款"],
                },
                yAxis: {
                  type: "value",
                  axisLabel: {
                    formatter(value) {
                      return formatNumber(value);
                    },
                  },
                },
                series: [
                  {
                    data: [
                      dailyAssetsData?.huobi ?? 0,
                      dailyAssetsData?.yingshou ?? 0,
                      dailyAssetsData?.yingfu ?? 0,
                      dailyAssetsData?.jiekuan ?? 0,
                    ],
                    type: "bar",
                  },
                ],
              }}
            />
          )}

          {actionType === "assetLiability" && (
            <Chart
              initOptions={{
                height: 260,
              }}
              chartOptions={{
                tooltip: {
                  trigger: "axis",
                  valueFormatter(value: any) {
                    return `${value * 100}%`;
                  },
                },
                xAxis: {
                  type: "category",
                  data: rateList.map((item) => dayjs(item.date).format("MM/DD")),
                },
                yAxis: {
                  type: "value",
                  // axisLabel: {
                  //   formatter(value) {
                  //     return `${value * 100}%`;
                  //   },
                  // },
                },
                series: [
                  {
                    data: rateList.map((item) => item.rate),
                    type: "line",
                  },
                ],
              }}
            />
          )}
        </div>

        {actionType === "sales" && (
          <div className="bg-white p-[20px] my-[10px]">
            <p className="header flex-y-center">
              <span>客户排行榜</span>
            </p>
            <List
              strong
              dividers
              className={clsx("mt-0 mb-0", styles.list)}
            >
              {userList?.map((user, index) => (
                <ListItem
                  key={index}
                  header={user.name}
                  title={user.date}
                >
                  <p
                    slot="after"
                    className="text-[16px] text-black"
                  >
                    <span className="number-font text-[20px]">{user.amount}</span> CNY
                  </p>
                </ListItem>
              ))}
            </List>
          </div>
        )}

        {actionType === "assetLiability" && (
          <div className={clsx("bg-white p-[20px] my-[10px]", styles.list)}>
            <List
              className="p-0 m-0"
              dividers
            >
              <ListItem>
                <p
                  slot="media"
                  className="w-[10px] h-[10px] rounded-full bg-[#17B99A]"
                ></p>
                <span slot="header">今日资产总额</span>
                <span
                  slot="title"
                  className="number-font text-[24px]"
                >
                  ¥{formatNumber(assetLiabilityData?.todayCashIn || 0)}
                </span>
              </ListItem>
              <ListItem>
                <p
                  slot="media"
                  className="w-[10px] h-[10px] rounded-full bg-[#FF0000]"
                ></p>
                <span slot="header">今日负债总额</span>
                <span
                  slot="title"
                  className="number-font text-[24px]"
                >
                  ¥{formatNumber(assetLiabilityData?.todayCashOut || 0)}
                </span>
              </ListItem>
              <ListItem>
                <p
                  slot="media"
                  className="w-[10px] h-[10px] rounded-full bg-[#5052C0]"
                ></p>
                <span slot="header">今日资产负债率</span>
                <span
                  slot="title"
                  className="number-font text-[24px]"
                >
                  {(assetLiabilityData?.todayCashRate ?? 0) * 100 || 0}%
                </span>
              </ListItem>
            </List>
          </div>
        )}
      </Page>
      <Actions
        opened={actionsOpened}
        onActionsClosed={() => setActionsOpened(false)}
      >
        <ActionsGroup>
          <ActionsLabel>切换图表</ActionsLabel>
          <ActionsButton
            textColor="#222"
            className="text-[14px]"
            onClick={() => {
              setActionsOpened(false);
              setActionType("sales");
              setDate({
                startDate: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
                endDate: dayjs().format("YYYY-MM-DD"),
              });
            }}
          >
            销售收入分析
          </ActionsButton>
          <ActionsButton
            textColor="#222"
            className="text-[14px]"
            onClick={() => {
              setActionsOpened(false);
              setActionType("dailyAssets");
              setDate({
                startDate: dayjs().format("YYYY-MM-DD"),
                endDate: dayjs().format("YYYY-MM-DD"),
              });
            }}
          >
            每日资产情况
          </ActionsButton>
          <ActionsButton
            textColor="#222"
            className="text-[14px]"
            onClick={() => {
              setActionsOpened(false);
              setActionType("assetLiability");
              setDate({
                startDate: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
                endDate: dayjs().format("YYYY-MM-DD"),
              });
            }}
          >
            资产负债率
          </ActionsButton>
        </ActionsGroup>
        <ActionsGroup>
          <ActionsButton
            color="red"
            className="text-[15px]"
            onClick={() => setActionsOpened(false)}
          >
            取消
          </ActionsButton>
        </ActionsGroup>
      </Actions>
    </>
  );
};

export default ReportPage;
