import useSwr from "swr";
import { useSetState } from "ahooks";
import { useEffect, useRef } from "react";
import {
  f7,
  Page,
  Navbar,
  NavRight,
  NavTitle,
  Button,
  List,
  Input,
  ListItem,
  Toolbar,
} from "framework7-react";

import VoiceButton from "components/VoiceButton";
import LabelRequired from "components/LabelRequired";
import InputPicker from "src/components/InputPicker";
import { showToast } from "src/shared/utils";

import { getEnum } from "./service";
import useAIStore from "src/store";

import type { FC } from "react";
import type { Tooltip } from "framework7/types";
import type { VoiceButtonRef } from "components/VoiceButton";
import type { VoucherDetailItem, UserState } from "src/store/slices/user/state";

const CertEditPage: FC<AIApp.Page & { data?: UserState["voucherData"] }> = ({
  f7route,
  f7Router,
  data,
}) => {
  // add or edit or view
  const { type = "add" } = f7route?.query ?? {};

  const deleteTooltip = useRef<Tooltip.Tooltip | null>(null);
  const nextButtonTooltip = useRef<Tooltip.Tooltip | null>(null);

  const voiceRef = useRef<VoiceButtonRef>(null);

  const { data: subjectEnum } = useSwr("subject", getEnum);
  const { data: natureSideEnum } = useSwr("nature-side", getEnum);
  const { data: customerEnum } = useSwr("customer-vendor", getEnum);

  const setVoucherDetail = useAIStore((state) => state.setVoucherDetail);
  const setDetailIndex = useAIStore((state) => state.setDetailIndex);
  const detailsIndex = useAIStore((state) => state.detailsIndex);
  const voucherData = useAIStore((state) => state.voucherData);
  const detailsLength = voucherData?.details?.length ?? 0;
  const [detailData, setDetailData] = useSetState({} as VoucherDetailItem);

  useEffect(() => {
    if (type === "edit") {
      setDetailData(voucherData?.details?.[detailsIndex ?? 0] ?? ({} as VoucherDetailItem));
    }
    if (type === "add") {
      setDetailData({
        lineNum: detailsLength + 1,
      } as VoucherDetailItem);
    }
    if (type === "view") {
      setDetailData(data?.details?.[detailsIndex ?? 0] ?? ({} as VoucherDetailItem));
    }
  }, [type, detailsIndex, voucherData, detailsLength, data]);

  const onPageInit = () => {};
  const onPageBeforeRemove = () => {
    setDetailIndex(0);
    if (deleteTooltip.current) deleteTooltip.current.destroy();
    if (nextButtonTooltip.current) nextButtonTooltip.current.destroy();
  };

  const onSave = () => {
    if (type === "edit") {
      // 编辑
      setVoucherDetail((prev) => {
        const details = prev?.details ?? [];
        details[detailsIndex ?? 0] = detailData;
        return {
          ...prev,
          details,
        };
      });
    }
    if (type === "add") {
      // 新增
      setVoucherDetail((prev) => {
        const details = prev?.details ?? [];
        return {
          ...prev,
          details: [...details, detailData],
        };
      });
    }
    showToast("保存成功");
    f7.views.main.router.back();
  };

  return (
    <Page
      onPageInit={onPageInit}
      onPageBeforeOut={() => {
        voiceRef.current?.toggle();
      }}
      onPageBeforeRemove={onPageBeforeRemove}
    >
      <Navbar backLink>
        <NavTitle>凭证行项目</NavTitle>
        <NavRight>
          {type !== "view" && (
            <Button
              type="button"
              iconF7="trash"
              iconSize={16}
              className="ripple delete-button"
              onClick={() => {
                f7.dialog.confirm("确定要删除吗?", () => {
                  setVoucherDetail((prev) => {
                    const details = prev?.details ?? [];
                    return {
                      ...prev,
                      details: details.filter((_, i) => i !== detailsIndex),
                    };
                  });
                  // 删除后，重新设置 detailsIndex
                  f7Router?.back();
                });
              }}
            />
          )}
          <Button
            type="button"
            iconSize={22}
            disabled={detailsIndex === 0}
            iconMaterial="arrow_circle_left"
            className="next-button"
            onClick={() => {
              setDetailIndex((detailsIndex ?? 0) - 1);
            }}
          ></Button>
          <Button
            type="button"
            iconSize={22}
            disabled={detailsIndex === (data?.details ? data?.details?.length : detailsLength) - 1}
            iconMaterial="arrow_circle_right"
            className="next-button"
            onClick={() => {
              setDetailIndex((detailsIndex ?? 0) + 1);
            }}
          ></Button>
        </NavRight>
      </Navbar>

      <List
        strong
        dividers
        className="my-[20px]"
      >
        <ListItem
          title="行号"
          after={detailData.lineNum || 0}
        />

        <ListItem link={type !== "view"}>
          <LabelRequired
            slot="title"
            readonly={type === "view"}
          >
            借贷方向
          </LabelRequired>
          <InputPicker
            slot="after"
            value={detailData.natureSideName}
            title="借贷方向"
            readonly={type === "view"}
            onPicker={(value) => {
              setDetailData({
                natureSideCode: value,
                natureSideName: natureSideEnum?.find((item) => item.value === value)?.label ?? "",
              });
            }}
            options={natureSideEnum ?? []}
          />
        </ListItem>

        <ListItem link={type !== "view"}>
          <LabelRequired
            slot="title"
            readonly={type === "view"}
          >
            CNY
          </LabelRequired>
          <InputPicker
            slot="after"
            value="CNY"
            title="CNY"
            readonly={type === "view"}
            options={[{ label: "CNY", value: "CNY" }]}
          />
        </ListItem>
        <ListItem link={type !== "view"}>
          <LabelRequired
            slot="title"
            readonly={type === "view"}
          >
            会计科目
          </LabelRequired>
          <InputPicker
            slot="after"
            value={detailData.subjectName}
            title="会计科目"
            readonly={type === "view"}
            options={subjectEnum ?? []}
            onPicker={(value) => {
              setDetailData({
                subjectCode: value,
                subjectName: subjectEnum?.find((item) => item.value === value)?.label ?? "",
              });
            }}
          />
        </ListItem>
        <ListItem
          link={type !== "view"}
          title="客户/供应商"
        >
          <InputPicker
            slot="after"
            value={detailData.customerVendorName}
            title="客户/供应商"
            readonly={type === "view"}
            options={customerEnum ?? []}
            onPicker={(value) => {
              setDetailData({
                customerVendorCode: value,
                customerVendorName: customerEnum?.find((item) => item.value === value)?.label ?? "",
              });
            }}
          />
        </ListItem>
        <ListItem title="记账金额">
          <Input
            wrap
            slot="after"
            clearButton={type !== "view"}
          >
            <input
              readOnly={type === "view"}
              placeholder="请输入记账金额"
              className="text-right text-black"
              value={detailData.transCurrencyAmount}
              onChange={(e) => {
                setDetailData({ transCurrencyAmount: e.target.value });
              }}
            />
          </Input>
        </ListItem>
        <ListItem
          after={
            +(detailData?.transCurrencyAmount ?? 0) > 0
              ? detailData.transCurrencyAmount
              : "自动计算"
          }
          title="本位币金额"
        ></ListItem>
        <ListItem title="行备注">
          <Input
            wrap
            slot="after"
            clearButton
          >
            <input
              readOnly={type === "view"}
              placeholder="请输入行备注"
              className="text-right text-black"
              value={detailData.lineDescription}
              onChange={(e) => {
                setDetailData({ lineDescription: e.target.value });
              }}
            />
          </Input>
        </ListItem>
      </List>

      {type !== "view" && (
        <Toolbar position="bottom">
          <Button
            className="w-full"
            onClick={onSave}
          >
            保存
          </Button>
        </Toolbar>
      )}
      {type !== "view" && (
        <VoiceButton
          ref={voiceRef}
          prevParams={(voucherData as any) ?? undefined}
          onChange={(value) => {
            setVoucherDetail(() => value as any);
          }}
        />
      )}
    </Page>
  );
};

export default CertEditPage;
