/**
 * Markdown 解析
 * created by anonymous
 * created time 10:39
 **/
import clsx from "clsx";
import { useRef, useCallback, memo, useEffect } from "react";
import copyText from "copy-to-clipboard";

import { md } from "./parse";
import styles from "./index.module.scss";
import { showToast } from "src/shared/utils";

import "./markdown.scss";
import "markdown-it-texmath/css/texmath.css";
import "highlight.js/styles/atom-one-dark.min.css";

import type { FC } from "react";

const MarkdownParse: FC<{
  content: string;
  useMarkdown?: boolean;
  className?: string;
  isParsing?: boolean;
}> = memo(({ content = "", className = "", useMarkdown = true, isParsing = false }) => {
  const parseContent = isParsing ? `${content} <span class="parse-loading"></span>` : content;
  const render = useMarkdown ? `${md.render(parseContent)}` : parseContent;

  const contentRef = useRef<HTMLDivElement>(null);
  const getAllCopyBtn = () => {
    if (contentRef.current) {
      return contentRef.current.querySelectorAll(".code-block-header__copy") as any;
    }
    return [];
  };
  const handleBtnCopy = useCallback(
    (e: Event) => {
      const btn = e.target as HTMLElement;
      const ancestorNode = btn.parentElement?.parentElement;
      if (ancestorNode) {
        const codeValue = ancestorNode.querySelector(".code-block-wrapper>code")?.textContent;
        copyText(codeValue || "");
        showToast("复制成功");
      }
    },
    [render],
  );
  const listenerCodeCopy = () => {
    for (const btn of getAllCopyBtn()) {
      btn.removeEventListener("click", handleBtnCopy, false);
      btn.addEventListener("click", handleBtnCopy, false);
    }
  };
  const removeBtnCopy = useCallback(() => {
    for (const btn of getAllCopyBtn()) {
      btn.removeEventListener("click", handleBtnCopy, false);
    }
  }, [render]);
  useEffect(() => {
    listenerCodeCopy();
    return removeBtnCopy;
  }, [render]);

  return (
    <div
      slot="text"
      className={clsx(className, styles["markdown-content"], "markdown-body")}
      dangerouslySetInnerHTML={{ __html: render }}
      ref={contentRef}
    />
  );
});

export default MarkdownParse;
