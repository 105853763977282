export interface VoucherDetailItem {
  lineNum: string | number;
  natureSideCode: string;
  subjectCode: string;
  subjectName: string;
  natureSideName: string;
  customerVendorCode: string;
  customerVendorName: string;
  transCurrencyAmount: string;
  lineDescription: string;
}
export interface UserState {
  voucherData?: {
    businessDate: string | Date | undefined;
    headerDescription: string;
    amount: string;
    voucherNumber: string;
    details: VoucherDetailItem[];
  } | null;
  detailsIndex?: number;
}

export const initialUserState: UserState = {
  voucherData: null,
  detailsIndex: 0,
};
