import useSwr from "swr";
import { useRef } from "react";
import { Page, Navbar, NavTitle, Icon, Button, List, ListItem, f7 } from "framework7-react";

import clsx from "clsx";
import styles from "./index.module.scss";
import type { FC } from "react";
import CountUp from "react-countup";

import { getHomeData } from "./service";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";

import yufukuan from "src/assets/images/zijin-yufukuandan.svg";
import yushoukuan from "src/assets/images/a-weibiaoti-2_huaban1.svg";
import yingfukuan from "src/assets/images/yingfukuanguanli.svg";
import yingshoukuan from "src/assets/images/icon_kuanxiangguanli.svg";
import kehushoukuan from "src/assets/images/kehushoukuan.svg";
import gongyingshang from "src/assets/images/gongyingshangfukuan.svg";

const Home: FC<AIApp.Page> = () => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const speak = async () => {
    if (audioRef.current) {
      audioRef.current.volume = 1;
      await audioRef.current?.play();
    }
  };

  const { data, mutate } = useSwr("home", getHomeData);
  const homeData = data?.data;

  const notice = [
    "今日宜发大财，宜拿AI大赛第一名",
    "今日有贵人相助，正能量围绕着你",
    "今日，你是自己命运的主宰！",
  ];
  const modules = [Autoplay];

  const navigateToVoucherList = (subjectCode: string) => () => {
    f7.views.main.router.navigate(`/voucherList/?subjectCode=${subjectCode}`);
  };

  return (
    <Page
      name="home"
      className={styles.page}
      ptr
      ptrMousewheel={true}
      onPtrRefresh={(done) => {
        mutate().then(() => {
          done();
        });
      }}
    >
      <Navbar>
        <NavTitle>爱记账信息科技有限公司</NavTitle>
      </Navbar>
      <header className="flex-y-center gap-[4px] h-[35px] px-[20px] text-[12px] text-primary">
        <span>本日运势：</span>
        <div className="flex-1 overflow-hidden h-full">
          <Swiper
            direction="vertical"
            loop={true}
            observer={true}
            className="h-full"
            modules={modules}
            observeParents={true}
            observeSlideChildren={true}
            resizeObserver={true}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            onInit={(swiper) => {
              setTimeout(() => {
                swiper?.autoplay?.start?.();
              }, 800);
            }}
          >
            {notice.map((item) => (
              <SwiperSlide
                key={item}
                className="h-full flex-y-center"
              >
                <span>{item}</span>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </header>
      <div className={clsx("p-[20px]", styles.header)}>
        <div className="flex-y-center justify-between">
          <div className="flex flex-col gap-[0px]">
            <p className="flex-y-center justify-center gap-[5px]">
              <span>本月现金流</span>
              <span className={styles.arrow}>
                <Icon
                  f7="arrow_left_right"
                  size={16}
                />
              </span>
            </p>

            <div className="text-[20px] number-font flex-y-center gap-[3px]">
              ¥
              <CountUp
                end={homeData?.cashAmount ?? 0}
                separator=","
                decimal="."
                className="number-font text-[26px]"
              />
            </div>
          </div>
          <Button
            fill
            round
            iconF7="speaker_2"
            iconSize={18}
            onClick={speak}
            className={clsx("text-[13px]", styles.bellButton)}
          >
            <span className="ml-[8px]">语音播报</span>
          </Button>
        </div>
        <div className="flex-y-center gap-[20px] mt-[25px] pb-[80px]">
          <div className="flex flex-col items-center flex-1 bg-primary rounded-md px-[12px] py-[10px]">
            <p className="flex-y-center justify-center gap-[5px]">
              <span>现金流入</span>
              <span className={styles.arrow}>
                <Icon
                  f7="arrow_left"
                  size={16}
                />
              </span>
            </p>
            <p className="text-[20px] number-font flex-y-center gap-[3px]">
              ¥
              <CountUp
                end={homeData?.cashIn ?? 0}
                separator=","
                decimal="."
                className="number-font text-[26px]"
              />
            </p>
          </div>
          <div className="flex flex-col items-center flex-1 bg-primary rounded-md px-[12px] py-[10px]">
            <p className="flex-y-center justify-center gap-[5px]">
              <span>现金流出</span>
              <span className={styles.arrow}>
                <Icon
                  f7="arrow_right"
                  size={16}
                />
              </span>
            </p>
            <p className="text-[20px] number-font flex-y-center gap-[3px]">
              ¥
              <CountUp
                end={homeData?.cashOut ?? 0}
                separator=","
                decimal="."
                className="number-font text-[26px]"
              />
            </p>
          </div>
        </div>
      </div>

      <div className={styles.dashboard}>
        <div className="flex-y-center justify-between">
          <div className="flex flex-col gap-[0px]">
            <p className="flex-y-center gap-[5px]">
              <span className={styles.arrow}>
                <Icon
                  f7="money_yen"
                  size={16}
                />
              </span>
              <span>本月利润</span>
            </p>
            <p className="text-[22px] number-font flex-y-center gap-[3px]">
              ¥
              <CountUp
                end={homeData?.monthAmount ?? 0}
                separator=","
                decimal="."
                className="number-font text-[30px]"
              />
            </p>
          </div>
        </div>
        <div className="flex mt-[25px]">
          <div className="flex flex-col flex-1">
            <p className="flex-y-center gap-[5px]">
              <span className={styles.arrow}>
                <Icon
                  f7="arrow_down"
                  size={16}
                />
              </span>
              <span>本月收入</span>
            </p>
            <p className="text-[20px] number-font flex-y-center gap-[3px]">
              ¥
              <CountUp
                end={homeData?.monthIn ?? 0}
                separator=","
                decimal="."
                className="number-font text-[24px]"
              />
            </p>
          </div>
          <div className="flex flex-col flex-1">
            <p className="flex-y-center gap-[5px]">
              <span className={styles.arrow}>
                <Icon
                  f7="arrow_up"
                  size={16}
                />
              </span>
              <span>本月支出</span>
            </p>
            <p className="text-[20px] number-font flex-y-center gap-[3px]">
              ¥
              <CountUp
                end={homeData?.monthOut ?? 0}
                separator=","
                decimal="."
                className="number-font text-[24px]"
              />
            </p>
          </div>
        </div>
      </div>

      <div className="menu p-[20px]">
        <p className="text-gray-400 mb-[10px] text-[13px]">往来账款</p>
        <List
          dividers
          className="m-0 p-0"
        >
          <ListItem header="预付款">
            <img
              className={clsx("w-[40px]")}
              slot="media"
              alt=""
              src={yufukuan}
            />
            <span
              slot="title"
              className="text-[20px] number-font"
            >
              {homeData?.yufu || 0}
            </span>
            <Button
              className={styles.check}
              onClick={navigateToVoucherList("1221")}
            >
              查看
            </Button>
          </ListItem>
          <ListItem header="预收款">
            <img
              className={clsx("w-[40px]")}
              slot="media"
              src={yushoukuan}
            />
            <span
              slot="title"
              className="text-[20px] number-font"
            >
              {homeData?.yushou || 0}
            </span>
            <Button
              className={styles.check}
              onClick={navigateToVoucherList("2203")}
            >
              查看
            </Button>
          </ListItem>
          <ListItem header="应付款">
            <img
              className={clsx("w-[40px]")}
              slot="media"
              src={yingfukuan}
            />
            <span
              slot="title"
              className="text-[20px] number-font"
            >
              {homeData?.yingfu || 0}
            </span>
            <Button
              className={styles.check}
              onClick={navigateToVoucherList("2202")}
            >
              查看
            </Button>
          </ListItem>
          <ListItem header="应收款">
            <img
              className={clsx("w-[40px]")}
              slot="media"
              src={yingshoukuan}
            />
            <span
              slot="title"
              className="text-[20px] number-font"
            >
              {homeData?.yingshou || 0}
            </span>
            <Button
              className={styles.check}
              onClick={navigateToVoucherList("1121")}
            >
              查看
            </Button>
          </ListItem>

          <ListItem header="本月客户收款">
            <img
              className={clsx("w-[40px]")}
              slot="media"
              src={kehushoukuan}
            />
            <span
              slot="title"
              className="text-[20px] number-font"
            >
              {homeData?.shoukuan || 0}
            </span>
            <Button
              className={styles.check}
              onClick={navigateToVoucherList("")}
            >
              查看
            </Button>
          </ListItem>
          <ListItem header="本月供应商付款">
            <img
              className={clsx("w-[40px]")}
              slot="media"
              src={gongyingshang}
            />
            <span
              slot="title"
              className="text-[20px] number-font"
            >
              {homeData?.fukuan || 0}
            </span>
            <Button
              className={styles.check}
              onClick={navigateToVoucherList("")}
            >
              查看
            </Button>
          </ListItem>
        </List>
      </div>

      <div className="flex-y-center gap-[20px] px-[20px] pb-[30px]">
        <a
          className="flex-1 flex-y-center justify-between h-[60px] px-[20px] bg-[#6467f01a] text-black rounded-md"
          href="/voucherList/"
        >
          <span className="text-[17px]">查账</span>
          <Icon
            f7="chevron_right"
            size={20}
          />
        </a>
        <a
          className="flex-1 flex-y-center justify-between h-[60px] px-[20px] bg-[#6467f01a] text-black rounded-md"
          href="/accounting/"
        >
          <span className="text-[17px]">记账</span>
          <Icon
            f7="chevron_right"
            size={20}
          />
        </a>
      </div>

      <audio
        controls
        ref={audioRef}
        className="hidden"
      >
        <source src="http://10.200.77.241:8888/fadian/main/home/broadcast" />
      </audio>
    </Page>
  );
};

export default Home;
