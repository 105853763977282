import { useState, forwardRef, useImperativeHandle, useRef } from "react";
import clsx from "clsx";
import { Icon, Progressbar, Preloader, Input } from "framework7-react";
import styles from "./index.module.scss";

import Timer, { TimerRef } from "../Timer";

import { VoiceRecorder } from "capacitor-voice-recorder";
import { base64ToBlob, blobToFile } from "shared/utils";
import { textToForm, voiceToText, TextToFormData } from "./service";

type VoiceButtonProps = {
  text?: string;
  /**
   * @example
   * text: 语音转文字
   * form: 语音转表单
   */
  type?: "text" | "form";
  prevParams?: TextToFormData;
  onChange?: (value: TextToFormData) => void;
};
export type VoiceButtonRef = {
  toggle: () => void;
};

const VoiceButton = forwardRef<VoiceButtonRef, VoiceButtonProps>(
  ({ text = "语音录入", onChange, prevParams }, ref) => {
    const [startRecord, setStartRecord] = useState(false);
    const [loading, setLoading] = useState(false);
    const [translated, setTranslated] = useState(false);

    const timerRef = useRef<TimerRef>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [voiceText, setVoiceText] = useState("");

    const startRecorder = () => {
      return VoiceRecorder.startRecording().then(() => {
        setStartRecord(!startRecord);
        setTimeout(() => {
          timerRef.current?.startTimer();
        }, 100);
      });
    };
    const stopRecorder = async () => {
      const recorderStatus = await VoiceRecorder.getCurrentStatus();
      if (recorderStatus.status === "RECORDING") {
        return VoiceRecorder.requestAudioRecordingPermission().then(() => {
          VoiceRecorder.stopRecording()
            .then((res) => {
              setTranslated(true);
              setLoading(false);
              const data = res.value.recordDataBase64;
              const voiceFile = blobToFile(base64ToBlob(data, res.value.mimeType), "voice.acc");
              const formData = new FormData();
              formData.append("file", voiceFile);
              voiceToText(formData)
                .then(({ data }) => {
                  if (data) {
                    setVoiceText(data);
                    inputRef.current?.focus();
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch(() => {
              setLoading(false);
              setStartRecord(false);
            });
        });
      }
      let params = {
        prompt: voiceText,
      };
      if (prevParams) {
        params = {
          ...params,
          ...prevParams,
        };
      }
      const { data, errCode } = await textToForm(params).finally(() => {
        setLoading(false);
      });
      if (+errCode === 200) {
        setVoiceText("");
      }
      onChange?.(data);
    };

    useImperativeHandle(ref, () => ({
      toggle() {
        setStartRecord(false);
        setTranslated(false);
        setLoading(false);
      },
    }));

    return (
      <>
        {!startRecord && (
          <div className="fab fab-center-bottom fab-morph w-[126px] h-[50px]">
            <div
              className="flex-center gap-[10px] w-full h-full rounded-[50px] text-white"
              onClick={startRecorder}
            >
              <Icon material="mic" />
              <span>{text}</span>
            </div>
          </div>
        )}

        {startRecord && (
          <div
            className={clsx(
              styles.progress,
              translated ? "flex pt-[10px]" : "flex-y-center py-[10px]",
              "fab fab-center-bottom shadow-lg z-[9999] gap-[12px] w-[70%] mx-auto text-black px-[10px] min-h-[50px] rounded-[10px]",
            )}
          >
            <span
              onClick={async () => {
                setStartRecord(false);
                setTranslated(false);
                setLoading(false);
                setVoiceText("");
                const status = await VoiceRecorder.getCurrentStatus();
                if (status.status === "RECORDING") {
                  return VoiceRecorder.stopRecording();
                }
              }}
            >
              <Icon
                material="close"
                size={20}
              />
            </span>

            {translated ? (
              <Input
                className={clsx("flex-1 pb-[10px]")}
                type="textarea"
                resizable={true}
                value={voiceText}
                placeholder="请输入"
                onChange={(e) => setVoiceText(e.target.value)}
              >
                <input
                  ref={inputRef}
                  placeholder="请输入"
                  className={styles.input}
                  // value={voiceText}
                  // onChange={(e) => setVoiceText(e.target.value)}
                />
              </Input>
            ) : (
              <div className="flex-center gap-[5px] flex-col flex-1">
                <Timer ref={timerRef} />

                <div className="flex-1 w-full">
                  <Progressbar
                    infinite
                    color="multi"
                  />
                </div>
              </div>
            )}
            <p
              onClick={() => {
                if (!loading) {
                  setLoading(true);
                  stopRecorder();
                  return;
                }
              }}
            >
              {loading ? (
                <Preloader />
              ) : (
                <Icon
                  material="check"
                  size={20}
                />
              )}
            </p>
          </div>
        )}
      </>
    );
  },
);
VoiceButton.displayName = "VoiceButton";

export default VoiceButton;
