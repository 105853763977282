import httpClient from "shared/httpClient";

export type HomeData = {
  cashIn: number;
  cashOut: number;
  cashAmount: number;
  monthIn: number;
  monthOut: number;
  monthAmount: number;
  yingfu: number;
  yingshou: number;
  yufu: number;
  yushou: number;
  shoukuan: number;
  fukuan: number;
};
export function getHomeData() {
  return httpClient.request<HomeData>(
    "/main/home/info",
    {},
    {
      method: "GET",
      hideLoading: true,
    },
  );
}

export function getBroadcast() {
  return httpClient.request(
    "/main/home/broadcast",
    {},
    {
      method: "GET",
      hideLoading: true,
    },
  );
}
