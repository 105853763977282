import useSwr from "swr";
import { queryDetail } from "../voucherList/service";
import type { FC } from "react";

import { Page, Navbar } from "framework7-react";

import DetailPage from "components/VoucherDetail";

const VoucherDetail: FC<AIApp.Page> = ({ f7route }) => {
  const { id = "" } = f7route?.params || {};
  const { data } = useSwr(id ? (id as string) : null, queryDetail);
  const voucherData = data?.data;

  return (
    <Page>
      <Navbar
        title="凭证详情"
        backLink
      />

      <DetailPage
        mode="view"
        data={voucherData}
      />
    </Page>
  );
};

export default VoucherDetail;
