import Tabs from "../pages/tabs";
import Home from "../pages/home";
import Questions from "../pages/questions";
import ReportsPage from "../pages/reports";
import AccountingPage from "../pages/accounting";
import CertEditPage from "../pages/certEdit";
import VoucherList from "../pages/voucherList";
import VoucherDetailPage from "../pages/voucherDetail";
import Chat from "../pages/chat";

import type { Router } from "framework7/types";

const routes: Router.RouteParameters[] = [
  {
    path: "/",
    component: Tabs,
    tabs: [
      {
        path: "/",
        id: "home",
        component: Home,
      },
      {
        path: "/accounting/",
        id: "accounting",
        component: AccountingPage,
      },
      {
        path: "/report/",
        id: "report",
        component: ReportsPage,
      },
      {
        path: "/question/",
        id: "question",
        component: Questions,
      },
    ],
  },
  {
    path: "/certEdit/",
    component: CertEditPage,
  },
  {
    path: "/voucherList/",
    component: VoucherList,
  },
  {
    path: "/voucherDetail/:id",
    component: VoucherDetailPage,
  },
  {
    path: "/chat/",
    component: Chat,
  },
];

export default routes;
