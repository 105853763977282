import { useStore } from "zustand";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";
import { persist, createJSONStorage, devtools } from "zustand/middleware";

import initialState from "./initialState";
import userAction, { type UserAction } from "./slices/user/action";

import type { InitialState } from "./initialState";
import type { StateCreator } from "zustand/vanilla";

export type VCSStore = InitialState & UserAction;

const createStore: () => StateCreator<VCSStore> =
  () =>
  (...params) => ({
    ...initialState,
    ...userAction(...params),
  });
export const vcsStore = createWithEqualityFn<VCSStore>()(
  devtools(
    persist(createStore(), {
      name: "aiStore",
      storage: createJSONStorage(() => localStorage),
    }),
  ),
  shallow,
);
// 可在 非 react 组件中使用
export const { getState, setState, subscribe } = vcsStore;

/**
 * React 组件中使用
 */
function useAIStore(): VCSStore;
function useAIStore<T>(selector: (state: VCSStore) => T): T;
function useAIStore<T>(selector?: (state: VCSStore) => T) {
  return useStore(vcsStore, selector!);
}

export default useAIStore;
