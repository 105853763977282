import { cloneDeep as LodashClone } from "lodash-es";
import { f7 } from "framework7-react";

export const cloneDeep = <T>(data: T) => LodashClone(data);

export const showToast = (text: string) => {
  return f7.toast
    .create({
      text,
      closeButton: true,
      closeButtonText: "关闭",
      closeTimeout: 3000,
      position: "top",
    })
    .open();
};

/**
 * 不足 10 的数字前面加 0
 * @param num
 */
export const addZero = (num: number | string) => {
  if (isNaN(Number(num))) return String(num);
  return parseInt(String(num)) < 10 ? `0${num}` : num;
};

/**
 * 去除字符串所有空格
 */
export const trimAll = (str: string | number) => String(str).replace(/\s/g, "");
/**
 * 去除对象中键值为 null, undefined, 同时去除字符串中所有空格
 */
export const nonNullableObject = <T extends AIApp.AnyObject>(record: T): T => {
  if (!record) return {} as T;
  const cloneObject = cloneDeep(record);
  const handleObject = <P>(value: P): P => {
    if (typeof value === "string") {
      // 去除首尾空格
      return value.trim() as P;
    }
    if (Array.isArray(value)) {
      return value.map(handleObject).filter((i) => i !== null && i !== undefined && i !== "") as P;
    }
    if (value !== null && typeof value === "object") {
      return Object.entries(value).reduce((acc, [key, value]) => {
        const cleanedValue = handleObject(value);
        if (
          cleanedValue !== null &&
          cleanedValue !== undefined &&
          cleanedValue !== "" &&
          JSON.stringify(cleanedValue) !== "{}" &&
          JSON.stringify(cleanedValue) !== "[]"
        ) {
          acc[key] = cleanedValue;
        }
        return acc;
      }, {} as AIApp.AnyObject) as P;
    }
    return value;
  };
  return handleObject(cloneObject);
};

export function base64ToBlob(base64: string, mimeType: string) {
  const transformBase64toBlob = (data: string) => {
    const bstr = atob(data); // 获得base64解码后的字符串
    let n = bstr.length;
    const u8arr = new Uint8Array(n); // 新建一个8位的整数类型数组，用来存放ASCII编码的字符串
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n); // 转换编码后才使用charCodeAt 找到Unicode编码
    }
    return u8arr;
  };
  return new Blob([transformBase64toBlob(base64)], { type: mimeType });
}
export function blobToFile(blob: Blob, fileName: string) {
  return new File([blob], fileName, {
    type: blob.type,
    lastModified: Date.now(), // 可以根据需要设置文件的最后修改时间
  });
}
