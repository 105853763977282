import { f7 } from "framework7-react";
import { nonNullableObject, showToast } from "./utils";

export type RequestOptions = {
  /**
   * 是否获取原始响应数据，例如下载文件
   */
  getOriginResponse?: boolean;
  /**
   * 接口请求成功时的提示信息，一些涉及到用户操作的接口请求，需要提示用户操作结果
   */
  successMessage?: string;
  /**
   * 是否应用 API 统一前缀，默认为 true
   */
  usePrefix?: boolean;
  /**
   * 请求超时时间，默认为 30000
   */
  timeout?: number;
  hideLoading?: boolean;
} & RequestInit;
export type RequestData = AIApp.AnyObject | FormData | null;

export const errorMsgRecord: Record<number, string> = {
  500: "啊哦，服务出错啦，请稍后再试",
  502: "啊哦，服务出错啦，请稍后再试",
  404: "服务找不到，请联系网站管理员",
  403: "服务请求出错啦",
  401: "身份信息已过期",
};

class HttpService {
  /**
   * 需要重定向的状态码
   */
  redirectCodes = [401, 301, 302];
  /**
   * 请求地址前缀
   */
  BASE_URL!: string;
  /**
   * 请求超时时间
   */
  timeout = 30000;

  constructor(baseURL: string) {
    this.BASE_URL = baseURL;
  }

  /**
   * fetch request
   * @param url - 请求地址
   * @param data - 请求数据
   * @param options - 请求配置，同 fetch 的 RequestInit
   * @example
   * options.getOriginResponse=true 获取原始响应数据，例如下载文件
   */
  request(
    url: string,
    data: RequestData,
    options: Omit<RequestOptions, "getOriginResponse"> & {
      getOriginResponse: true;
    },
  ): Promise<Response>;

  /**
   * fetch request
   * @param url - 请求地址
   * @param data - 请求数据
   * @param options - 请求配置，同 fetch 的 RequestInit
   */
  request<T = any>(
    url: string,
    data?: RequestData,
    options?: RequestOptions,
  ): Promise<AIApp.HttpResponse<T>>;

  /**
   * fetch request
   * @param url - 请求地址
   * @param data - 请求数据
   * @param options - 请求配置，同 fetch 的 RequestInit
   */
  async request<T = any>(url: string, data: RequestData = {}, options?: RequestOptions) {
    const {
      usePrefix = true,
      successMessage,
      timeout = 30000,
      hideLoading = false,
      getOriginResponse = false,
      ...userOptions
    } = options || {};

    const requestOptions: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      ...userOptions,
    };
    if (!requestOptions?.headers || Object.keys(requestOptions.headers).length === 0) {
      requestOptions.headers = {};
    }

    if (requestOptions.method?.toUpperCase() !== "GET" && data) {
      requestOptions.body =
        data instanceof FormData ? data : JSON.stringify(nonNullableObject(data));
    }

    let requestURL = url;
    if (requestOptions.method?.toUpperCase() === "GET" && data) {
      // @ts-expect-error
      const params = new URLSearchParams(data as AIApp.AnyObject);
      requestURL = `${requestURL}?${params.toString()}`;
    }

    const formatURL = usePrefix ? `${this.BASE_URL}${requestURL}` : requestURL;

    const abortController = new AbortController();
    requestOptions.signal = userOptions.signal ?? abortController.signal;
    const timer = setTimeout(() => {
      abortController.abort({
        status: 500,
      });
      showToast("服务请求超时，请稍后再试");
    }, timeout);
    !hideLoading && this.showLoading();
    const response = await fetch(formatURL, requestOptions).finally(() => {
      clearTimeout(timer);
      !hideLoading && this.hideLoading();
    });

    if (!response.ok) {
      const errorMsg = errorMsgRecord[response.status];
      showToast(errorMsg ? `${errorMsg}： ${response.statusText}` : response.statusText);
      throw response;
    }
    if (!getOriginResponse) {
      const result = await this.handleResponse<T>({
        response,
        successMessage,
      });
      return result as AIApp.HttpResponse<T>;
    }
    return response;
  }

  async handleResponse<T>(options: { response: Response; successMessage?: string }) {
    const { response, successMessage } = options ?? {};
    const result: AIApp.HttpResponse<T> = await response.json();
    if ("errCode" in result && +result.errCode !== 200) {
      showToast(result.errMsg);
      return result;
    }
    if (successMessage) {
      showToast(successMessage);
    }
    return result;
  }
  showLoading() {
    return f7.preloader.show();
  }
  hideLoading() {
    return f7.preloader.hide();
  }
}

const httpClient = new HttpService("http://10.200.77.241:8888/fadian");
export default httpClient;
