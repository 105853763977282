import useSwr from "swr";
import dayjs from "dayjs";
import { useSetState } from "ahooks";
import { useMemo, useEffect, useState, useRef } from "react";
import {
  Page,
  Navbar,
  NavRight,
  NavTitle,
  List,
  ListInput,
  ListItem,
  Icon,
  Panel,
  Button,
  f7,
  Toolbar,
} from "framework7-react";
import InputPicker from "src/components/InputPicker";

import { getEnum } from "../certEdit/service";
import { voucherQuery } from "./service";
import type { FC } from "react";
import type { VoucherQueryParams, VoucherQueryResponse } from "./service";

const VoucherList: FC<AIApp.Page> = ({ f7route }) => {
  const { subjectCode = "" } = f7route?.query ?? {};
  useEffect(() => {
    setQueryParams({
      subjectCode: subjectCode as string,
    });
  }, []);

  const [queryParams, setQueryParams] = useSetState<VoucherQueryParams>({
    pageIndex: 1,
    pageSize: 20,
    businessDateBegin: "",
    businessDateEnd: "",
    businessDate: "",
    createTime: "",
    headerDescription: "",
    subjectCode: "",
    customerVendorCode: "",
    transCurrencyAmount: "",
    lineDescription: "",
  });
  const businessDateRangeValue = useMemo(
    () =>
      queryParams.businessDateBegin && queryParams.businessDateEnd
        ? [new Date(queryParams.businessDateBegin), new Date(queryParams.businessDateEnd)]
        : [],
    [queryParams.businessDateBegin, queryParams.businessDateEnd],
  );

  const [queryModalParams, setQueryModalParams] = useSetState<VoucherQueryParams>({
    businessDateBegin: "",
    businessDateEnd: "",
    businessDate: "",
    createTime: "",
    headerDescription: "",
    subjectCode: "",
    customerVendorCode: "",
    transCurrencyAmount: "",
    lineDescription: "",
  });
  // const businessDateModalRangeValue = useMemo(
  //   () =>
  //     queryModalParams.businessDateBegin && queryModalParams.businessDateEnd
  //       ? [new Date(queryModalParams.businessDateBegin), new Date(queryModalParams.businessDateEnd)]
  //       : [],
  //   [queryModalParams.businessDateBegin, queryModalParams.businessDateEnd],
  // );

  const [panelOpened, setPanelOpened] = useState(false);

  const { data: subjectEnum } = useSwr("subject", getEnum);
  const { data: customerEnum } = useSwr("customer-vendor", getEnum);

  const [listData, setListData] = useState<VoucherQueryResponse["list"]>([]);
  const getInit = async (pageIndex: number = 1) => {
    const { data, errCode } = await voucherQuery({ ...queryParams, pageIndex });
    if (+errCode === 200) {
      const dataList = data?.list || [];
      setListData((prev) => [...prev, ...dataList]);
      return dataList;
    }
  };

  const allowInfinite = useRef(true);
  const [showPreloader, setShowPreloader] = useState(false);
  const loadMore = async () => {
    if (!allowInfinite.current) return;
    allowInfinite.current = false;
    setShowPreloader(true);
    const pageIndex = (queryParams?.pageIndex ?? 0) + 1;
    setQueryParams({
      pageIndex,
    });
    const data = await getInit(pageIndex);
    if (data?.length === 0) {
      return setShowPreloader(false);
    }
    allowInfinite.current = true;
  };

  return (
    <Page
      infinite
      infiniteDistance={50}
      infinitePreloader={showPreloader}
      onInfinite={loadMore}
      onPageInit={() => getInit()}
      ptr
      onPtrRefresh={async (done) => {
        await getInit(1);
        done();
        setQueryParams({
          pageIndex: 1,
        });
        allowInfinite.current = true;
      }}
    >
      <Navbar backLink>
        <NavTitle>凭证查询</NavTitle>
        <NavRight>
          <Button
            type="button"
            onClick={() => setPanelOpened(!panelOpened)}
          >
            <Icon
              f7="slider_horizontal_3"
              size={20}
              className="text-[#333]"
            />
          </Button>
        </NavRight>
      </Navbar>

      <div className="flex-y-center bg-white px-[20px] h-[45px] mt-[10px]">
        <span className="text-[13px]">业务发生日期：</span>
        <List className="m-0 p-0 flex-1">
          <ListInput
            readonly
            clearButton
            type="datepicker"
            className="p-0"
            placeholder="请选择时间"
            value={businessDateRangeValue}
            calendarParams={{
              backdrop: true,
              rangePicker: true,
              maxDate: new Date(),
              on: {
                change(calendar, values: any) {
                  if (values?.length === 0) {
                    setQueryParams({
                      businessDateBegin: "",
                      businessDateEnd: "",
                      pageIndex: 1,
                    });
                  }
                  if (values?.length === 2) {
                    calendar.close();
                    setQueryParams({
                      businessDateBegin: dayjs(values[0]).format("YYYY-MM-DD"),
                      businessDateEnd: dayjs(values[1]).format("YYYY-MM-DD"),
                      pageIndex: 1,
                    });
                  }
                },
              },
            }}
          />
        </List>
      </div>

      <List
        strong
        dividers
        className="my-[10px]"
      >
        {listData.map((item) => (
          <ListItem
            link
            key={item.id}
            onClick={() => {
              f7.views.main.router.navigate(`/voucherDetail/${item.id}`);
            }}
          >
            <span slot="header">{item.headerDescription}</span>
            <p
              className="flex-y-center gap-[4px]"
              slot="after"
            >
              <span className="number-font text-[18px] ">{item.amount || 0}</span>
              <span>CNY</span>
            </p>

            <span slot="title">{item.businessDate}</span>
          </ListItem>
        ))}
      </List>

      <Panel
        right
        floating
        opened={panelOpened}
        onPanelClosed={() => setPanelOpened(false)}
        containerEl="#panel-page"
      >
        <Page className="bg-light">
          <span className="block mt-[20px]  mb-[5px] text-[12px] text-gray-500">常用条件</span>
          <List
            className="my-0"
            dividers
          >
            {/* <ListInput
              readonly
              clearButton
              type="datepicker"
              className="p-0"
              placeholder="请选择时间"
              value={businessDateModalRangeValue}
              calendarParams={{
                backdrop: true,
                rangePicker: true,
                maxDate: new Date(),
                on: {
                  change(calendar, values: any) {
                    if (values?.length === 0) {
                      setQueryModalParams({
                        businessDateBegin: "",
                        businessDateEnd: "",
                      });
                    }
                    if (values?.length === 2) {
                      calendar.close();
                      setQueryModalParams({
                        businessDateBegin: dayjs(values[0]).format("YYYY-MM-DD"),
                        businessDateEnd: dayjs(values[1]).format("YYYY-MM-DD"),
                      });
                    }
                  },
                },
              }}
            /> */}
            <ListItem
              title="客户/供应商"
              link
            >
              <InputPicker
                slot="after"
                title="客户/供应商"
                options={customerEnum ?? []}
                onPicker={(value) => {
                  setQueryModalParams({
                    customerVendorCode: value,
                  });
                }}
              />
            </ListItem>
            <ListInput
              resizable
              label="摘要"
              type="textarea"
              placeholder="请输入"
              value={queryModalParams.headerDescription}
              onChange={(e) => {
                setQueryModalParams({
                  headerDescription: e.target.value,
                });
              }}
            />
          </List>

          <span className="block mt-[20px]  mb-[5px] text-[12px] text-gray-500">其他条件</span>
          <List
            className="my-0"
            dividers
          >
            <ListItem
              title="会计科目"
              link
            >
              <InputPicker
                slot="after"
                title="会计科目"
                options={subjectEnum ?? []}
                onPicker={(value) => {
                  setQueryModalParams({
                    subjectCode: value,
                  });
                }}
              />
            </ListItem>
            <ListInput
              readonly
              clearButton
              label="金额"
              className="p-0"
              placeholder="请输入金额"
              value={queryModalParams.transCurrencyAmount}
              onChange={(e) => {
                setQueryModalParams({
                  transCurrencyAmount: e.target.value,
                });
              }}
            />
            <ListInput
              readonly
              clearButton
              label="记录日期"
              type="datepicker"
              className="p-0"
              placeholder="请选择时间"
              calendarParams={{
                backdrop: true,
                // rangePicker: true,
                maxDate: new Date(),
                on: {
                  change(calendar, values) {
                    console.log(values);
                    if ((values as any[]).length === 2) {
                      calendar.close();
                    }
                  },
                },
              }}
            />
            <ListInput
              resizable
              label="行备注"
              type="textarea"
              placeholder="请输入"
            ></ListInput>
          </List>

          <Toolbar bottom>
            <Button
              iconF7="trash"
              iconSize={16}
              className="flex-y-center gap-[4px]"
              onClick={() => {
                setPanelOpened(false);
                setQueryModalParams({
                  businessDateBegin: "",
                  businessDateEnd: "",
                  businessDate: "",
                  createTime: "",
                  headerDescription: "",
                  subjectCode: "",
                  customerVendorCode: "",
                  transCurrencyAmount: "",
                  lineDescription: "",
                });
                setQueryParams({
                  pageIndex: 1,
                  pageSize: 20,
                  businessDateBegin: "",
                  businessDateEnd: "",
                  businessDate: "",
                  createTime: "",
                  headerDescription: "",
                  subjectCode: "",
                  customerVendorCode: "",
                  transCurrencyAmount: "",
                  lineDescription: "",
                });
              }}
            >
              重置
            </Button>
            <Button
              iconSize={16}
              iconF7="search"
              className="flex-y-center gap-[4px]"
              onClick={() => {
                setQueryParams({
                  ...queryParams,
                  ...queryModalParams,
                });
                setPanelOpened(false);
              }}
            >
              查询
            </Button>
          </Toolbar>
        </Page>
      </Panel>
    </Page>
  );
};

export default VoucherList;
