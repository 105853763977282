import httpClient from "shared/httpClient";

export type SalesReportData = {
  tableList: {
    amount: string;
    name: string;
    date: string;
  }[];
  graphList: {
    amount: number;
    date: string;
  }[];
};
export function getSalesReport(data: { startDate: string; endDate: string }) {
  return httpClient.request<SalesReportData>(
    `/main/report/one/${data.startDate}/${data.endDate}`,
    {},
    {
      method: "GET",
    },
  );
}

export function getDayReport(date: string) {
  return httpClient.request<{
    huobi: string;
    yingshou: string;
    yingfu: string;
    jiekuan: string;
  }>(
    `/main/report/two/${date}`,
    {},
    {
      method: "GET",
    },
  );
}

export type AssetLiabilityData = {
  details: { rate: string; date: string }[];
  todayCashIn: number;
  todayCashOut: number;
  todayCashRate: number;
};
export function getAssetLiability(data: { startDate: string; endDate: string }) {
  return httpClient.request<AssetLiabilityData>(
    `/main/report/three/${data.startDate}/${data.endDate}`,
    {},
    {
      method: "GET",
    },
  );
}
