import dayjs from "dayjs";
import { forwardRef, useEffect, useImperativeHandle, useMemo, memo, useState } from "react";
import {
  f7,
  Button,
  List,
  ListInput,
  ListItem,
  SwipeoutActions,
  SwipeoutButton,
} from "framework7-react";
import LabelRequired from "components/LabelRequired";

import useAIStore from "src/store";

import type { UserState } from "src/store/slices/user/state";

export type VoucherDetailRef = {
  reset(): void;
};
export type VoucherDetailProps = {
  data?: UserState["voucherData"];
  mode?: "edit" | "view";
};

const VoucherDetail = forwardRef<VoucherDetailRef, VoucherDetailProps>((props, ref) => {
  const { data, mode = "edit" } = props;

  const setDetailIndex = useAIStore((state) => state.setDetailIndex);
  const setVoucherDetail = useAIStore((state) => state.setVoucherDetail);

  const [detailData, setDetailData] = useState(
    data ?? ({} as NonNullable<UserState["voucherData"]>),
  );
  useEffect(() => {
    setDetailData(
      data ??
        ({
          businessDate: dayjs().format("YYYY-MM-DD"),
          headerDescription: "",
          details: [] as any,
        } as NonNullable<UserState["voucherData"]>),
    );
  }, [data]);

  useImperativeHandle(ref, () => ({
    reset() {
      setVoucherDetail(() => ({
        businessDate: undefined,
        headerDescription: "",
        details: [] as any,
      }));
      setDetailData({
        businessDate: dayjs().format("YYYY-MM-DD"),
        headerDescription: "",
        details: [] as any,
      } as NonNullable<UserState["voucherData"]>);
    },
  }));

  const businessDate = useMemo(
    () => (detailData?.businessDate ? [new Date(detailData.businessDate)] : []),
    [detailData?.businessDate],
  );

  return (
    <>
      <List
        strong
        dividers
        inset
        className="my-0 mx-[5px] pt-[10px]"
      >
        {mode === "view" && (
          <ListInput
            label="凭证编码"
            type="text"
            placeholder="请输入凭证编码"
            readonly
            value={detailData.voucherNumber}
            onChange={() => {}}
          />
        )}
        <ListInput
          required
          readonly={mode === "view"}
          // clearButton={mode !== "view"}
          type="datepicker"
          value={businessDate}
          calendarParams={{
            sheetPush: true,
            maxDate: new Date(),
            closeOnSelect: true,
          }}
          placeholder="请选择业务发生日期"
          onChange={() => {}}
          onCalendarChange={(e: [Date] | Date) => {
            let value = e;
            if (e instanceof Array) {
              value = e[0];
            }
            const formatDate = dayjs(value as Date).format("YYYY-MM-DD");
            if (formatDate !== detailData.businessDate) {
              setDetailData({
                ...detailData,
                businessDate: dayjs(value as Date).format("YYYY-MM-DD"),
              });
              setVoucherDetail(() => ({
                businessDate: dayjs(value as Date).format("YYYY-MM-DD"),
              }));
            }
          }}
        >
          <LabelRequired
            slot="label"
            readonly={mode === "view"}
          >
            业务发生日期
          </LabelRequired>
        </ListInput>
        <ListInput
          required
          resizable
          type="textarea"
          placeholder="请输入摘要"
          readonly={mode === "view"}
          clearButton={mode !== "view"}
          value={detailData.headerDescription}
          onChange={(e) => {
            const value = e.target.value;
            setDetailData({ ...detailData, headerDescription: value });
            setVoucherDetail(() => ({
              headerDescription: value,
            }));
          }}
        >
          <LabelRequired
            slot="label"
            readonly={mode === "view"}
          >
            摘要
          </LabelRequired>
        </ListInput>
      </List>

      <div
        className="bg-white rounded-[8px] m-[10px] overflow-hidden
      "
      >
        <div className="flex justify-between gap-[4px]  px-[16px] py-[10px] ">
          <LabelRequired readonly={mode === "view"}>凭证明细</LabelRequired>
          {mode !== "view" && (
            <Button
              tonal
              href="/certEdit/?type=add"
              iconMaterial="add_circle_fill"
              className="text-[12px] w-auto flex-y-center gap-[4px]"
              iconSize={18}
              onClick={() => {
                setDetailIndex(0);
              }}
            >
              手工创建行项目
            </Button>
          )}
        </div>
        <List
          dividers
          className="my-0 mx-0"
        >
          {detailData?.details?.map?.((item, index) => (
            <ListItem
              link
              swipeout
              after="查看详情"
              key={item.lineNum}
              header={item.subjectName}
              onClick={() => {
                setDetailIndex(index);
                f7.views.main.router.navigate(`/certEdit/?type=${mode}`, {
                  props: {
                    data: mode === "view" ? data : null,
                  },
                });
              }}
            >
              <span
                slot="title"
                className="text-gray-400 number-font pt-[4px]"
              >
                {item.transCurrencyAmount || 0} CNY
              </span>
              {mode === "edit" && (
                <SwipeoutActions right>
                  <SwipeoutButton
                    delete
                    confirmText="确定删除此项凭证吗?"
                    onClick={() => {
                      // setDetailData((prev) => {
                      //   const newDetails = prev.details?.filter((_, i) => i !== index);
                      //   return {
                      //     ...prev,
                      //     details: newDetails,
                      //   };
                      // });
                    }}
                  >
                    删除
                  </SwipeoutButton>
                </SwipeoutActions>
              )}
            </ListItem>
          ))}
        </List>
      </div>
    </>
  );
});
VoucherDetail.displayName = "VoucherDetail";

export default memo(VoucherDetail);
