import httpClient from "shared/httpClient";

export type VoucherDetail = {
  businessDate: string;
  headerDescription: string;
  amount: string;
  details: {
    lineNum: string;
    natureSideCode: string;
    subjectCode: string;
    customerVendorCode: string;
    transCurrencyAmount: string;
    lineDescription: string;
  }[];
};

export function voucherDetail() {
  return httpClient.request<VoucherDetail>(``);
}

export type EnumData = {
  code: string;
  name: string;
};
export async function getEnum(key: string) {
  const { data = [] } = await httpClient.request<EnumData[]>(
    `/main/down-list/${key}`,
    {},
    {
      method: "GET",
    },
  );
  return (
    data.map((item) => ({
      label: item.name,
      value: item.code,
    })) ?? []
  );
}
