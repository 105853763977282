import { useRef } from "react";
import { Page, Navbar, NavLeft, NavRight, NavTitle, Button, f7 } from "framework7-react";
import VoiceButton from "components/VoiceButton";
import styles from "./index.module.scss";

import { saveVoucher } from "./service";
import VoucherDetail, { VoucherDetailRef } from "components/VoucherDetail";

import useAIStore from "src/store";

import type { FC } from "react";
import type { SaveVoucherParams } from "./service";
import type { VoiceButtonRef } from "components/VoiceButton";

const AccountingPage: FC = () => {
  const voucherData = useAIStore((state) => state.voucherData);
  const setVoucherDetail = useAIStore((state) => state.setVoucherDetail);

  const voucherRef = useRef<VoucherDetailRef>(null);

  const voiceRef = useRef<VoiceButtonRef>(null);

  const saveVoucherData = async () => {
    const data = await saveVoucher({
      ...voucherData,
    } as SaveVoucherParams);
    if (data.errCode === "200") {
      f7.toast
        .create({
          text: "记账成功",
          position: "top",
          closeTimeout: 2000,
          closeButtonText: "关闭",
        })
        .open();
      setVoucherDetail(() => null);
    }
  };

  return (
    <Page className={styles.page}>
      <Navbar>
        <NavLeft>
          <Button
            tonal
            onClick={() => {
              voucherRef.current?.reset();
            }}
          >
            重置
          </Button>
        </NavLeft>
        <NavTitle>爱记账信息科技有限公司</NavTitle>
        <NavRight>
          <Button
            fill
            className="ripple"
            onClick={saveVoucherData}
          >
            确定
          </Button>
        </NavRight>
      </Navbar>

      <VoucherDetail
        ref={voucherRef}
        data={voucherData}
      />

      <VoiceButton
        ref={voiceRef}
        prevParams={(voucherData as any) ?? undefined}
        onChange={(value) => {
          setVoucherDetail(() => value as any);
        }}
      />
    </Page>
  );
};

export default AccountingPage;
