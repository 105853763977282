import highlightJS from "highlight.js";
import MarkdownIt from "markdown-it";
import MarkdownMath from "markdown-it-texmath";

const highlightBlock = (str: string, lang?: string) => {
  const langHtml = lang ? `<span class="code-block-header__lang">${lang}</span>` : "";
  // ${langHtml.toUpperCase()}
  return `<div class="code-block"><div class="code-block-header flex items-center justify-between"><span class="language"></span><span>${langHtml.toUpperCase()}</span><span class="code-block-header__copy">复制</span></div><pre class="code-block-wrapper"><code class="hljs code-block-body p-0 ${lang}">${str}</code></pre></div>`;
};

const md = new MarkdownIt({
  html: true,
  linkify: true,
  highlight(code, language) {
    const filterCode = code.replace(`<span class="parse-loading"></span>`, "");
    const validLang = !!(language && highlightJS.getLanguage(language));
    if (validLang) {
      const lang = language ?? "";
      return highlightBlock(highlightJS.highlight(filterCode, { language: lang }).value, lang);
    }
    return highlightBlock(highlightJS.highlightAuto(filterCode).value, "");
  },
});
md.use(MarkdownMath, {
  delimiters: ["brackets", "dollars", "doxygen", "gitlab", "julia", "kramdown", "beg_end"],
  katexOptions: {
    macros: { "\\RR": "\\mathbb{R}" },
    displayMode: true,
    strict: false,
  },
});

const defaultRender =
  md.renderer.rules.link_open ||
  function (tokens, idx, options, _env, self) {
    return self.renderToken(tokens, idx, options);
  };

// 调整链接跳转为打开新窗口
md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  // Add a new `target` attribute, or replace the value of the existing one.
  tokens[idx].attrSet("target", "_blank");
  // Pass the token to the default renderer.
  return defaultRender(tokens, idx, options, env, self);
};
// 调整图片默认展示
// md.renderer.rules.image = function (tokens, idx) {
//   const src = tokens[idx].attrGet("src") || "";
//   const id = "markdown_image-" + Math.random() * 5;
//   const timer = setTimeout(() => {
//     const ele = document.getElementById(id);
//     if (ele) {
//       createRoot(ele).render(<Image src={src} />);
//       clearTimeout(timer);
//     }
//   }, 50);
//   return renderToString(<div id={id} />);
// };

export { md };
