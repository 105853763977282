import httpClient from "shared/httpClient";
import type { UserState } from "src/store/slices/user/state";

export type VoucherQueryParams = {
  businessDateBegin?: string;
  businessDateEnd?: string;
  businessDate?: string;
  createTime?: string;
  headerDescription?: string;
  subjectCode?: string;
  customerVendorCode?: string;
  transCurrencyAmount?: string;
  lineDescription?: string;
  pageIndex?: number;
  pageSize?: number;
};
export type VoucherQueryResponse = {
  total: number;
  list: {
    id: string;
    headerDescription: string;
    amount: string;
    businessDate: string;
  }[];
};
export function voucherQuery(data: VoucherQueryParams) {
  return httpClient.request<VoucherQueryResponse>("/main/voucher/list", data);
}

export type VoucherDetailData = UserState["voucherData"] & {
  voucherNumber: string;
};
export function queryDetail(id: string) {
  return httpClient.request<VoucherDetailData>(
    `/main/voucher/detail/${id}`,
    {},
    {
      method: "GET",
    },
  );
}
