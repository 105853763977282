/**
 * 计时器
 */
import clsx from "clsx";
import { useState, useRef, forwardRef, useImperativeHandle, memo, HTMLAttributes } from "react";
import { addZero } from "shared/utils";

import styles from "./index.module.scss";

type TimerProps = HTMLAttributes<HTMLDivElement> & {
  start?: boolean;
  disabled?: boolean;
};
type Timer = ReturnType<typeof setInterval>;
export type TimerRef = {
  startTimer: () => void;
  pauseTimer: () => void;
  stopTimer: () => void;
};

/**
 * 解析时间，将时间转换为时分秒
 * @param time
 */
const resolveTime = (time: number) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;
  return {
    hours: addZero(hours),
    minutes: addZero(minutes),
    seconds: addZero(seconds),
  };
};

const Timer = forwardRef<TimerRef, TimerProps>((props, ref) => {
  const { className, ...restProps } = props;
  const [time, setTime] = useState<number>(1);
  const timer = useRef<Timer | null>(null);
  const startTimer = () => {
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
    timer.current = setInterval(() => {
      setTime((prev) => prev + 1);
    }, 1000);
  };
  const stopTimer = () => {
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
    setTime(0);
  };
  const pauseTimer = () => {
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      startTimer,
      stopTimer,
      pauseTimer,
    }),
    [time],
  );

  const formatTime = resolveTime(time);

  return (
    <div
      className={clsx("flex-center text-primary text-[16px] number-font", styles.timer, className)}
      {...restProps}
    >
      <span>{formatTime.hours}</span>
      <span>:</span>
      <span>{formatTime.minutes}</span>
      <span>:</span>
      <span>{formatTime.seconds}</span>
    </div>
  );
});
Timer.displayName = "Timer";

export default memo(Timer);
