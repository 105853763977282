import React from "react";
// import vConsole from "vconsole";
import ReactDOM from "react-dom/client";
import { Capacitor } from "@capacitor/core";
import { StatusBar, Style } from "@capacitor/status-bar";

// Import Framework7
import Framework7 from "framework7/lite-bundle";

// Import Framework7-React Plugin
import Framework7React from "framework7-react";

// Import Framework7 Styles
import "framework7/css/bundle";

import App from "./App";

// Init F7 React Plugin
Framework7.use(Framework7React);

import "./styles";

const isNative = Capacitor.isNativePlatform();

if (isNative) {
  StatusBar.setStyle({ style: Style.Dark });
  StatusBar.setOverlaysWebView({ overlay: true });
}

const rootEl = document.getElementById("root");
if (rootEl) {
  const root = ReactDOM.createRoot(rootEl);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}
