import { useRef, useEffect, useState } from "react";
import { Input, f7 } from "framework7-react";

import type { FC } from "react";
import type { Picker } from "framework7/types";
import type { InputProps } from "framework7-react/components/input.js";

type ListItemPickerProps = InputProps & {
  onPicker?: (value: string) => void;
  placeholder?: string;
  options?: {
    label: string;
    value: string | number;
  }[];
  title?: string;
  pickerParams?: Picker.Parameters;
};
const InputPicker: FC<ListItemPickerProps> = (props) => {
  const {
    onPicker,
    value = "",
    title = "请选择",
    placeholder = "请选择",
    options = [],
    pickerParams = {},
    readonly = false,
    ...restProps
  } = props;

  const [inputValue, setInputValue] = useState("");
  const [pickerValue, setPickerValue] = useState("");

  const inputEl = useRef<HTMLInputElement>(null);
  const pickerRef = useRef<Picker.Picker | null>(null);
  const pickerInit = () => {
    if (!options?.length || !inputEl.current) return;

    pickerRef.current = f7.picker.create({
      inputEl: inputEl.current,
      rotateEffect: true,
      backdrop: true,
      // toolbarCloseText: "确定",
      // formatValue(values) {
      //   return values[1] as any;
      // },
      cols: [
        {
          textAlign: "center",
          values: options.map((item) => item.label),
        },
      ],
      on: {
        opened(picker) {
          picker.$el.find(".picker-confirm").on("click", function (e: Event) {
            console.log(e);

            e.preventDefault();
            // 阻止冒泡
            e.stopPropagation();
            const value = options.find((item) => item.label === (picker.value as any[])[0]);
            const selectValue = value ?? options?.[0];
            if (selectValue) {
              onPicker?.(selectValue.value as string);
              setInputValue(selectValue.label);
              setPickerValue(selectValue.label);
            }
          });
        },
      },
      renderToolbar() {
        return `<div class="toolbar">
          <div class="toolbar-inner">
            <span class="link text-[14px] sheet-close">取消</span>
            <span class="link text-[14px]">${title}</span>
            <span class="link text-[14px] picker-confirm sheet-close">确认</span>
          </div>
        </div>`;
      },
      ...pickerParams,
      value: [pickerValue ?? options?.[0]?.label],
    });
  };
  useEffect(() => {
    !pickerRef.current && !readonly && pickerInit();
    return () => {
      pickerRef.current?.destroy();
      pickerRef.current = null;
    };
  }, [options, pickerParams, title, pickerValue]);
  useEffect(() => {
    setPickerValue(value as string);
    if (readonly) {
      const selectValue = options.find((item) => item.label === value);
      console.log(selectValue, "selectValue");
      setInputValue(selectValue?.label ?? "");
    }
  }, [value, readonly]);

  return (
    <Input
      {...restProps}
      className="h-full"
    >
      <input
        ref={inputEl}
        value={inputValue}
        placeholder={placeholder}
        readOnly={true}
        className="w-full text-right bg-transparent text-[13px] text-[#333]"
        onChange={() => {}}
      />
    </Input>
  );
};

export default InputPicker;
