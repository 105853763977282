import httpClient from "shared/httpClient";

export function voiceToText(data: FormData) {
  return httpClient.request("/main/voice2Text", data, {});
}

export type SaveVoucherParams = {
  businessDate: string;
  headerDescription: string;
  amount: string;
  details: {
    lineNum: string;
    natureSideCode: string;
    subjectCode: string;
    customerVendorCode: string;
    transCurrencyAmount: string;
    lineDescription: string;
  }[];
};
export function saveVoucher(data: SaveVoucherParams) {
  return httpClient.request("/main/voucher/save", data);
}
