import type { FC, PropsWithChildren } from "react";

const LabelRequired: FC<PropsWithChildren<{ slot?: string; readonly?: boolean }>> = ({
  children,
  slot,
  readonly = false,
}) => {
  return (
    <p
      className="flex-y-center gap-[2px]"
      slot={slot}
    >
      {!readonly && <span className="text-red-600">*</span>}
      <span className="text-[13px]">{children}</span>
    </p>
  );
};

export default LabelRequired;
