import {
  Page,
  Navbar,
  Messages,
  Message,
  Messagebar,
  Link,
  f7ready,
  f7,
  Icon,
  Toolbar,
  Progressbar,
  Preloader,
} from "framework7-react";
import clsx from "clsx";
import { useState, useEffect, useRef } from "react";
import type { FC } from "react";

import aiStream from "src/shared/aiStream";
import { sendChat } from "./service";
import RobotAvatar from "src/assets/images/robot.png";
import { showToast } from "src/shared/utils";
import MarkdownParse from "src/components/MarkdownParse";
import Timer, { TimerRef } from "src/components/Timer";
import { VoiceRecorder } from "capacitor-voice-recorder";
import { base64ToBlob, blobToFile } from "shared/utils";
import { voiceToText } from "components/VoiceButton/service";

import type { MessageProps } from "framework7-react/components/message.js";

const Chat: FC = () => {
  const [messageText, setMessageText] = useState("");
  const [messagesData, setMessagesData] = useState<MessageProps[]>([
    {
      type: "received",
      first: true,
      last: true,
      tail: true,
      avatar: RobotAvatar,
      text: "您好，我是智能客服，有什么可以帮助您的吗？",
    },
  ]);

  const messagebar = useRef<Messagebar.Messagebar | null>(null);

  useEffect(() => {
    f7ready(() => {
      messagebar.current = f7.messagebar.get(".messagebar");
    });
  }, []);
  const isFirstMessage = (message: any, index: number) => {
    const previousMessage = messagesData[index - 1];
    if (message.isTitle) return false;
    if (
      !previousMessage ||
      previousMessage.type !== message.type ||
      previousMessage.name !== message.name
    )
      return true;
    return false;
  };
  const isLastMessage = (message: any, index: number) => {
    const nextMessage = messagesData[index + 1];
    if (message.isTitle) return false;
    if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name)
      return true;
    return false;
  };
  const isTailMessage = (message: any, index: number) => {
    const nextMessage = messagesData[index + 1];
    if (message.isTitle) return false;
    if (!nextMessage || nextMessage.type !== message.type || nextMessage.name !== message.name)
      return true;
    return false;
  };

  const [startRecord, setStartRecord] = useState(false);
  const [loading, setLoading] = useState(false);
  const timerRef = useRef<TimerRef>(null);
  const startRecorder = () => {
    return VoiceRecorder.startRecording().then(() => {
      setStartRecord(!startRecord);
      timerRef.current?.startTimer();
    });
  };
  const stopRecorder = async () => {
    const recorderStatus = await VoiceRecorder.getCurrentStatus();
    if (recorderStatus.status === "RECORDING") {
      return VoiceRecorder.requestAudioRecordingPermission().then(() => {
        VoiceRecorder.stopRecording()
          .then((res) => {
            setLoading(false);
            const data = res.value.recordDataBase64;
            const voiceFile = blobToFile(base64ToBlob(data, res.value.mimeType), "voice.acc");
            const formData = new FormData();
            formData.append("file", voiceFile);
            voiceToText(formData)
              .then(({ data }) => {
                if (data) {
                  setMessageText(data);
                }
              })
              .catch((err) => {
                console.log(err);
              })
              .finally(() => {
                setLoading(false);
                setStartRecord(false);
              });
          })
          .catch(() => {
            setLoading(false);
            setStartRecord(false);
          });
      });
    }
  };

  const conversationId = useRef("");
  const blankEle = useRef<HTMLDivElement>(null);
  const abortController = useRef<AbortController | null>(null);
  const sendMessage = async () => {
    const text = messageText.replace(/\n/g, "<br>").trim();
    if (text.length === 0) return showToast("请输入问题");
    setMessagesData((prev) => [
      ...prev,
      {
        type: "sent",
        text,
      },
      {
        type: "received",
        typing: true,
        first: true,
        last: true,
        tail: true,
        isReply: true,
        avatar: RobotAvatar,
      },
    ]);
    setTimeout(() => {
      blankEle.current?.scrollIntoView({ block: "end" });
    }, 50);
    const response = await sendChat({
      conversationId: conversationId.current,
      prompt: text,
    });
    const stream = response.clone().body;
    if (!stream) return;
    abortController.current = new AbortController();
    await aiStream.readStream({
      stream,
      signal: abortController.current.signal,
      onRead(messageData) {
        const { done, responseText = "" } = messageData;
        if (done)
          return setMessagesData((prev) => {
            const lastMessage = prev[prev.length - 1];
            (lastMessage as any).isReply = false;
            return [...prev];
          });
        setMessagesData((prev) => {
          const lastMessage = prev[prev.length - 1];
          lastMessage.text = responseText;
          lastMessage.typing = false;
          return [...prev];
        });
        setTimeout(() => {
          blankEle.current?.scrollIntoView({ block: "end" });
        }, 0);
      },
      onStart(messageData) {
        conversationId.current = messageData?.conversationId ?? "";
      },
    });
    setMessageText("");
  };

  return (
    <Page>
      <Navbar
        title="提问"
        backLink
      ></Navbar>
      {!startRecord && (
        <Messagebar
          placeholder="请输入问题"
          value={messageText}
          onInput={(e) => setMessageText(e.target.value)}
        >
          <Link
            iconMaterial="mic"
            slot="inner-start"
            onClick={() => {
              setStartRecord(!startRecord);
              startRecorder();
            }}
          />
          <Link
            iconF7="arrow_up_circle_fill"
            slot="inner-end"
            onClick={sendMessage}
          />
        </Messagebar>
      )}

      {startRecord && (
        <Toolbar bottom>
          <div
            className={clsx("flex-y-center py-[10px] gap-[12px] w-full text-black min-h-[50px]")}
          >
            <span
              onClick={async () => {
                setStartRecord(false);
                setLoading(false);
                setMessageText("");
                const status = await VoiceRecorder.getCurrentStatus();
                if (status.status === "RECORDING") {
                  return VoiceRecorder.stopRecording();
                }
              }}
            >
              <Icon
                material="close"
                size={20}
              />
            </span>

            <div className="flex-center gap-[5px] flex-col flex-1">
              <Timer ref={timerRef} />

              <div className="flex-1 w-full">
                <Progressbar
                  infinite
                  color="multi"
                />
              </div>
            </div>
            <p
              onClick={() => {
                if (!loading) {
                  setLoading(true);
                  stopRecorder();
                  return;
                }
              }}
            >
              {loading ? (
                <Preloader />
              ) : (
                <Icon
                  material="check"
                  size={20}
                />
              )}
            </p>
          </div>
        </Toolbar>
      )}

      <Messages>
        {messagesData.map((message: any, index) => (
          <Message
            key={index}
            type={message.type}
            image={message.image}
            name={message.name}
            avatar={message.avatar}
            typing={message.typing}
            first={isFirstMessage(message, index)}
            last={isLastMessage(message, index)}
            tail={isTailMessage(message, index)}
          >
            {message.text && (
              <MarkdownParse
                content={message.text}
                isParsing={message.isReply && message.type === "received"}
              />
            )}
          </Message>
        ))}
        <div
          ref={blankEle}
          className="h-[80px]"
        />
      </Messages>
    </Page>
  );
};

export default Chat;
