import { Page, Toolbar, Link, Tabs, Tab } from "framework7-react";
import { useMemo } from "react";
import styles from "./index.module.scss";

import type { FC } from "react";

interface TabsItem {
  id: string;
  icon: string;
  path: string;
  text: string;
  f7Icon?: string;
}
const TabsPage: FC = () => {
  const tabs: TabsItem[] = useMemo(
    () => [
      {
        id: "home",
        icon: "home_filled",
        path: "/",
        text: "首页",
      },
      {
        id: "accounting",
        icon: "edit_square_filled",
        path: "/accounting/",
        text: "记账",
      },
      {
        id: "report",
        icon: "equalizer_filled",
        path: "/report/",
        text: "报表",
      },
      {
        id: "question",
        icon: "",
        f7Icon: "chat_bubble_2_fill",
        path: "/question/",
        text: "提问",
      },
    ],
    [],
  );

  return (
    <Page>
      <Toolbar
        tabbar
        icons
        bottom
        className={styles.toolbar}
      >
        {tabs.map((item: TabsItem) => (
          <Link
            key={item.id}
            href={item.path}
            text={item.text}
            iconMaterial={item.icon}
            iconF7={item.f7Icon}
            routeTabId={item.id}
            tabLink={`#${item.id}`}
          />
        ))}
      </Toolbar>

      <Tabs routable>
        {tabs.map((item: TabsItem) => (
          <Tab
            id={item.id}
            key={item.id}
            onTabHide={() => {
              window.dispatchEvent(new Event("tabHide", { detail: item.id } as any));
            }}
            onTabShow={() => {
              window.dispatchEvent(new Event("tabShow", { detail: item.id } as any));
            }}
          />
        ))}
      </Tabs>
    </Page>
  );
};

export default TabsPage;
