import httpClient from "shared/httpClient";
import type { VoucherDetailItem } from "src/store/slices/user/state";

export function voiceToText(data: FormData) {
  return httpClient.request<string>("/main/voice2Text", data, {
    headers: {},
  });
}

export type TextToFormData = {
  conversationId: string;
  businessDate: string;
  headerDescription: string;
  amount: string;
  details: VoucherDetailItem[];
};
export function textToForm(data: { prompt: string }) {
  return httpClient.request<TextToFormData>("/main/voucher/generate", data, {});
}
