import httpClient from "src/shared/httpClient";

export type ChatParams = {
  conversationId?: string;
  prompt: string;
};
export function sendChat(data: ChatParams) {
  return httpClient.request("/main/chat/ask", data, {
    getOriginResponse: true,
    hideLoading: true,
  });
}
