import type { StateCreator } from "zustand/vanilla";
import type { UserState } from "./state";

export interface UserAction {
  setVoucherDetail: (
    cb: (data: UserState["voucherData"]) => Partial<UserState["voucherData"]>,
  ) => void;
  setDetailIndex: (index: number) => void;
}

const userAction: StateCreator<UserState & UserAction, [], [], UserAction> = (set, get) => ({
  setVoucherDetail(cb) {
    const { voucherData = {} as UserState["voucherData"] } = get();
    const newData = cb?.(voucherData);
    if (newData === null) {
      return set({ voucherData: null });
    }
    set({
      voucherData: {
        ...voucherData,
        ...newData,
      } as UserState["voucherData"],
    });
  },
  setDetailIndex(index) {
    set({ detailsIndex: index });
  },
});

export default userAction;
